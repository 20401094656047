
@import 'animate.css/animate.css';

@import 'bootstrap/dist/css/bootstrap.min.css';
// @import "assets/css/owl.carousel.css";
@import 'assets/css/all.css' ;
@import 'assets/css/solid.min.css';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'animate.css/animate.min.css'; 
@import 'wowjs/css/libs/animate.css';
@import 'assets/css/grid.css';
@import "assets/css/style.css";

.container {
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    width: 80% !important;
}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1440px; 
   }
}
.top_section {
    width: 80%;
    margin: 0 auto;
    max-width: 100%;
    background: #2F2F2F;
    padding: 13px 0 23px;
    position: relative;
}
.current{
    font-weight: bolder;
    color: black !important;
}