/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: top;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
.container .alpha {
  margin-left: 0px;
}
.container .omega {
  margin-right: 0px;
}
.row {
  margin-left: -30px;
}
.row:before,
.row:after {
  display: table;
  content: "";
  line-height: 0;
}
.row:after {
  clear: both;
}
[class*="grid_"] {
  float: left;
  min-height: 1px;
  margin-left: 30px;
}
.container {
  width: 1170px;
}
.grid_12 {
  width: 1170px;
}
.grid_11 {
  width: 1070px;
}
.grid_10 {
  width: 970px;
}
.grid_9 {
  width: 870px;
}
.grid_8 {
  width: 770px;
}
.grid_7 {
  width: 670px;
}
.grid_6 {
  width: 570px;
}
.grid_5 {
  width: 470px;
}
.grid_4 {
  width: 370px;
}
.grid_3 {
  width: 270px;
}
.grid_2 {
  width: 170px;
}
.grid_1 {
  width: 70px;
}
.preffix_12 {
  margin-left: 1230px;
}
.preffix_11 {
  margin-left: 1130px;
}
.preffix_10 {
  margin-left: 1030px;
}
.preffix_9 {
  margin-left: 930px;
}
.preffix_8 {
  margin-left: 830px;
}
.preffix_7 {
  margin-left: 730px;
}
.preffix_6 {
  margin-left: 630px;
}
.preffix_5 {
  margin-left: 530px;
}
.preffix_4 {
  margin-left: 430px;
}
.preffix_3 {
  margin-left: 330px;
}
.preffix_2 {
  margin-left: 230px;
}
.preffix_1 {
  margin-left: 130px;
}
.container {
  margin-right: auto;
  margin-left: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.container:before,
.container:after {
  display: table;
  content: "";
  line-height: 0;
}
.container:after {
  clear: both;
}
@media (min-width: 980px) and (max-width: 1199px) {
  .row {
    margin-left: -20px;
  }
  .row:before,
  .row:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row:after {
    clear: both;
  }
  [class*="grid_"] {
    float: left;
    min-height: 1px;
    margin-left: 20px;
  }
  .container {
    width: 940px;
  }
  .grid_12 {
    width: 940px;
  }
  .grid_11 {
    width: 860px;
  }
  .grid_10 {
    width: 780px;
  }
  .grid_9 {
    width: 700px;
  }
  .grid_8 {
    width: 620px;
  }
  .grid_7 {
    width: 540px;
  }
  .grid_6 {
    width: 460px;
  }
  .grid_5 {
    width: 380px;
  }
  .grid_4 {
    width: 300px;
  }
  .grid_3 {
    width: 220px;
  }
  .grid_2 {
    width: 140px;
  }
  .grid_1 {
    width: 60px;
  }
  .preffix_12 {
    margin-left: 980px;
  }
  .preffix_11 {
    margin-left: 900px;
  }
  .preffix_10 {
    margin-left: 820px;
  }
  .preffix_9 {
    margin-left: 740px;
  }
  .preffix_8 {
    margin-left: 660px;
  }
  .preffix_7 {
    margin-left: 580px;
  }
  .preffix_6 {
    margin-left: 500px;
  }
  .preffix_5 {
    margin-left: 420px;
  }
  .preffix_4 {
    margin-left: 340px;
  }
  .preffix_3 {
    margin-left: 260px;
  }
  .preffix_2 {
    margin-left: 180px;
  }
  .preffix_1 {
    margin-left: 100px;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .row {
    margin-left: -20px;
  }
  .row:before,
  .row:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row:after {
    clear: both;
  }
  [class*="grid_"] {
    float: left;
    min-height: 1px;
    margin-left: 20px;
  }
  .container {
    width: 748px;
  }
  .grid_12 {
    width: 748px;
  }
  .grid_11 {
    width: 684px;
  }
  .grid_10 {
    width: 620px;
  }
  .grid_9 {
    width: 556px;
  }
  .grid_8 {
    width: 492px;
  }
  .grid_7 {
    width: 428px;
  }
  .grid_6 {
    width: 364px;
  }
  .grid_5 {
    width: 300px;
  }
  .grid_4 {
    width: 236px;
  }
  .grid_3 {
    width: 172px;
  }
  .grid_2 {
    width: 108px;
  }
  .grid_1 {
    width: 44px;
  }
  .preffix_12 {
    margin-left: 788px;
  }
  .preffix_11 {
    margin-left: 724px;
  }
  .preffix_10 {
    margin-left: 660px;
  }
  .preffix_9 {
    margin-left: 596px;
  }
  .preffix_8 {
    margin-left: 532px;
  }
  .preffix_7 {
    margin-left: 468px;
  }
  .preffix_6 {
    margin-left: 404px;
  }
  .preffix_5 {
    margin-left: 340px;
  }
  .preffix_4 {
    margin-left: 276px;
  }
  .preffix_3 {
    margin-left: 212px;
  }
  .preffix_2 {
    margin-left: 148px;
  }
  .preffix_1 {
    margin-left: 84px;
  }
}
.select-menu {
  display: none !important;
}
@media (max-width: 767px) {
  .container {
    width: 420px;
    padding: 0 0px;
  }
  .row {
    margin-left: 0;
  }
  [class*="grid_"] {
    float: none;
    display: block;
    width: 100%;
    margin-left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .select-menu {
    display: block !important;
  }
}
@media (max-width: 479px) {
  body {
    padding: 0;
  }
  .container {
    width: 300px;
    padding: 0 15px;
  }
  .row {
    margin-left: 0;
  }
}
